var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"page-container"},[_c('Breadcrumbs',{attrs:{"currentpage":"Je account"}}),_c('h1',[_vm._v("Je account: "+_vm._s(_vm.account.CompanyName))]),_c('p',[_vm._v(" Je kan de status en details van je aanvragen en reserveringen hieronder opvolgen. Wil je een aanvraag of reservering opnieuw plaatsen? Klik dan onderaan de pagina op ‘opnieuw aanvragen’. ")]),_c('p',[_vm._v(" Heb je vragen over je reservering(en)? Neem dan contact op met "),_c('a',{attrs:{"href":"mailto:uitleendienst@vlaamsbrabant.be"}},[_vm._v("uitleendienst@vlaamsbrabant.be")])]),(_vm.alertInfoMessage)?_c('b-alert',{attrs:{"show":"","variant":"info"}},[_c('font-awesome-icon',{attrs:{"icon":"exclamation-circle"}}),_vm._v(" "+_vm._s(_vm.alertInfoMessage)+" ")],1):_vm._e(),_c('h3',[_vm._v("Overzicht van je reservering(en)")]),(_vm.reservations.length > 0)?_c('p',{staticClass:"reservation-count"},[_vm._v(" Reservering(en) gevonden: "+_vm._s(_vm.reservationsTotal)+" ")]):_vm._e(),(_vm.reservations.length > 0)?_c('b-table',{attrs:{"responsive":"","items":_vm.reservations,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(ReservationID)",fn:function(data){return [_c('router-link',{attrs:{"to":{
          name: 'reservation',
          params: { reservationID: data.item.ReservationID }
        }}},[_vm._v("#"+_vm._s(data.item.ReservationID))])]}},{key:"cell(Status)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm.reservationStatus(data.item.Status, data.item.WarehouseID)))])]}},{key:"cell(WarehouseID)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.WarehouseID.replace('MINI ', '')))])]}},{key:"cell(TotalIncVAT)",fn:function(data){return [_c('div',{staticClass:"form-group text-right"},[_vm._v(" € "+_vm._s((Math.round(data.item.TotalIncVAT * 100) / 100) .toFixed(2) .replace('.', ','))+" ")])]}},{key:"cell(DateTimeBusinessStart)",fn:function(data){return [_vm._v(_vm._s(_vm.formatDate(data.item.DateTimeBusinessStart)))]}},{key:"cell(DateTimeBusinessEnd)",fn:function(data){return [_vm._v(_vm._s(_vm.formatDate(data.item.DateTimeBusinessEnd)))]}},{key:"cell(MissingTestimonies)",fn:function(data){return [(data.item.MissingTestimonies.length == 1)?_c('span',[(data.item.MissingTestimonies[0].Description == 'PA Attest')?_c('span',[_c('router-link',{attrs:{"to":{
              name: 'reservation',
              params: { reservationID: data.item.ReservationID }
            }}},[_vm._v("PA Attest uploaden")])],1):_vm._e(),(
            data.item.MissingTestimonies[0].Description == 'Risico analyse'
          )?_c('span',[_c('router-link',{attrs:{"to":{
              name: 'reservation',
              params: { reservationID: data.item.ReservationID }
            }}},[_vm._v("Risico Analyse uploaden")])],1):_vm._e()]):_vm._e(),(data.item.MissingTestimonies.length == 2)?_c('span',[_c('router-link',{attrs:{"to":{
            name: 'reservation',
            params: { reservationID: data.item.ReservationID }
          }}},[_vm._v("Bestanden uploaden")])],1):_vm._e(),(!data.item.MissingTestimonies[0])?_c('span',[_vm._v("Geen")]):_vm._e()]}},{key:"cell(DefaultDiscount)",fn:function(data){return [_c('router-link',{attrs:{"to":{
          name: 'reservation',
          params: { reservationID: data.item.ReservationID }
        }}},[_vm._v("Bekijk details >")])]}}],null,false,3944442664)}):_vm._e(),(_vm.reservations.length > 0)?_c('div',{staticClass:"pagination"},[_c('b-button',{staticClass:"mr-1 ml-1",attrs:{"variant":"link","disabled":_vm.loadingReservations || _vm.reservationsIndex <= 1},on:{"click":function($event){_vm.reservationsIndex--}}},[_c('chevron-left-icon')],1),(_vm.reservationsIndex > 1)?_c('b-button',{staticClass:"mr-1 ml-1",on:{"click":function($event){_vm.reservationsIndex--}}},[_vm._v(_vm._s(_vm.reservationsIndex - 1))]):_vm._e(),_c('b-button',{staticClass:"mr-1 ml-1",attrs:{"variant":"primary"}},[_vm._v(_vm._s(_vm.reservationsIndex))]),(_vm.reservationsIndex < _vm.reservationsIndexCount)?_c('b-button',{staticClass:"mr-1 ml-1",attrs:{"disabled":_vm.loadingReservations},on:{"click":function($event){_vm.reservationsIndex++}}},[_vm._v(_vm._s(_vm.reservationsIndex + 1))]):_vm._e(),_c('b-button',{attrs:{"disabled":_vm.loadingReservations || _vm.reservationsIndex >= _vm.reservationsIndexCount,"variant":"link"},on:{"click":function($event){_vm.reservationsIndex++}}},[_c('chevron-right-icon')],1)],1):_vm._e(),_c('small',[_vm._v(" Het is niet mogelijk om producten in je openstaande reservaties aan te passen. Indien je dit toch wenst, "),_c('a',{attrs:{"href":""}},[_vm._v("neem dan contact met ons op.")]),_vm._v(" Items toevoegen aan een reservatie vereist een nieuwe reservatie met een bijkomende administratiekost. ")]),(_vm.account.RoleName == 'portal keyuser' && _vm.subUsers.length > 0)?_c('div',[_c('h3',{staticClass:"pt-4"},[_vm._v("Gebruikers van deze organisatie")]),_c('b-row',[_vm._l((_vm.subUsers),function(user){return _c('b-col',{key:user.UserName,staticClass:"mt-4",attrs:{"xs":"12","sm":"12","md":"12","lg":"6","xl":"4"}},[_c('b-card',{class:{
            'card-user': true,
            'mt-4': true,
            'card-inactive': !user.IsActive
          }},[_c('p',[_vm._v(" "+_vm._s(user.UserName)+" "),_c('br'),_c('small',[_vm._v(" "+_vm._s(user.RoleName === 'portal keyuser' ? 'Hoofdgebruiker' : 'Gebruiker')+" ")])]),_c('router-link',{staticClass:"btn btn-outline-primary",attrs:{"to":{ name: 'editSub', params: { username: user.UserName } }}},[_vm._v("Gegevens wijzigen")])],1)],1)}),_c('b-col',{staticClass:"pt-4 mt-4",attrs:{"cols":"12"}},[(_vm.subUsersMaxReached)?_c('b-alert',{attrs:{"show":"","variant":"warning"}},[_c('font-awesome-icon',{attrs:{"icon":"exclamation-circle"}}),_vm._v("Het maximale aantal sub gebruikers is bereikt, het is niet mogelijk om een nieuwe sub gebruiker aan te maken. ")],1):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"mt-4",attrs:{"cols":"12"}},[_c('router-link',{class:{
                btn: true,
                'btn-primary': true,

                'd-none': _vm.subUsersMaxReached
              },attrs:{"title":_vm.subUsersMaxReached
                  ? 'Maximale aantal contactpersonen berijkt'
                  : 'Nieuw contactpersoon',"to":{ name: 'newSub' }}},[_vm._v("Nieuwe gebruiker toevoegen")])],1),_c('b-col',{staticClass:"mt-4",attrs:{"cols":"12"}},[_c('hr'),_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{ name: 'editSub', params: { username: _vm.user.UserName } }}},[_vm._v("Mijn gegevens wijzigen")])],1),_c('b-col',{staticClass:"mt-4"},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":"/registreren"}},[_vm._v("Nieuwe vereniging registreren")])],1)],1)],1)],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }