<template>
  <b-container class="page-container">
    <Breadcrumbs currentpage="Je account" />
    <h1>Je account: {{ account.CompanyName }}</h1>
    <p>
      Je kan de status en details van je aanvragen en reserveringen hieronder
      opvolgen. Wil je een aanvraag of reservering opnieuw plaatsen? Klik dan
      onderaan de pagina op ‘opnieuw aanvragen’.
    </p>

    <p>
      Heb je vragen over je reservering(en)? Neem dan contact op met
      <a href="mailto:uitleendienst@vlaamsbrabant.be"
        >uitleendienst@vlaamsbrabant.be</a
      >
    </p>
    <b-alert v-if="alertInfoMessage" show variant="info">
      <font-awesome-icon icon="exclamation-circle" />
      {{ alertInfoMessage }}
    </b-alert>

    <h3>Overzicht van je reservering(en)</h3>
    <p v-if="reservations.length > 0" class="reservation-count">
      Reservering(en) gevonden: {{ reservationsTotal }}
    </p>

    <b-table
      v-if="reservations.length > 0"
      responsive
      :items="reservations"
      :fields="fields"
    >
      <template v-slot:cell(ReservationID)="data">
        <router-link
          :to="{
            name: 'reservation',
            params: { reservationID: data.item.ReservationID }
          }"
          >#{{ data.item.ReservationID }}</router-link
        >
      </template>
      <template v-slot:cell(Status)="data">
        <span>{{
          reservationStatus(data.item.Status, data.item.WarehouseID)
        }}</span>
      </template>
      <template v-slot:cell(WarehouseID)="data">
        <span>{{ data.item.WarehouseID.replace('MINI ', '') }}</span>
      </template>
      <template v-slot:cell(TotalIncVAT)="data">
        <div class="form-group text-right">
          &euro;
          {{
            (Math.round(data.item.TotalIncVAT * 100) / 100)
              .toFixed(2)
              .replace('.', ',')
          }}
        </div>
      </template>

      <template v-slot:cell(DateTimeBusinessStart)="data">{{
        formatDate(data.item.DateTimeBusinessStart)
      }}</template>
      <template v-slot:cell(DateTimeBusinessEnd)="data">{{
        formatDate(data.item.DateTimeBusinessEnd)
      }}</template>
      <template v-slot:cell(MissingTestimonies)="data">
        <span v-if="data.item.MissingTestimonies.length == 1">
          <span
            v-if="data.item.MissingTestimonies[0].Description == 'PA Attest'"
          >
            <router-link
              :to="{
                name: 'reservation',
                params: { reservationID: data.item.ReservationID }
              }"
              >PA Attest uploaden</router-link
            >
          </span>
          <span
            v-if="
              data.item.MissingTestimonies[0].Description == 'Risico analyse'
            "
          >
            <router-link
              :to="{
                name: 'reservation',
                params: { reservationID: data.item.ReservationID }
              }"
              >Risico Analyse uploaden</router-link
            ></span
          >
        </span>
        <span v-if="data.item.MissingTestimonies.length == 2"
          ><router-link
            :to="{
              name: 'reservation',
              params: { reservationID: data.item.ReservationID }
            }"
            >Bestanden uploaden</router-link
          ></span
        >
        <span v-if="!data.item.MissingTestimonies[0]">Geen</span>
      </template>
      <template v-slot:cell(DefaultDiscount)="data">
        <router-link
          :to="{
            name: 'reservation',
            params: { reservationID: data.item.ReservationID }
          }"
          >Bekijk details ></router-link
        >
      </template>
    </b-table>
    <div v-if="reservations.length > 0" class="pagination">
      <b-button
        variant="link"
        class="mr-1 ml-1"
        :disabled="loadingReservations || reservationsIndex <= 1"
        @click="reservationsIndex--"
        ><chevron-left-icon
      /></b-button>
      <b-button
        v-if="reservationsIndex > 1"
        class="mr-1 ml-1"
        @click="reservationsIndex--"
        >{{ reservationsIndex - 1 }}</b-button
      >
      <b-button class="mr-1 ml-1" variant="primary">{{
        reservationsIndex
      }}</b-button>
      <b-button
        v-if="reservationsIndex < reservationsIndexCount"
        class="mr-1 ml-1"
        :disabled="loadingReservations"
        @click="reservationsIndex++"
        >{{ reservationsIndex + 1 }}</b-button
      >

      <b-button
        :disabled="
          loadingReservations || reservationsIndex >= reservationsIndexCount
        "
        variant="link"
        @click="reservationsIndex++"
        ><chevron-right-icon
      /></b-button>
    </div>
    <small>
      Het is niet mogelijk om producten in je openstaande reservaties aan te
      passen. Indien je dit toch wenst,
      <a href>neem dan contact met ons op.</a> Items toevoegen aan een
      reservatie vereist een nieuwe reservatie met een bijkomende
      administratiekost.
    </small>
    <div v-if="account.RoleName == 'portal keyuser' && subUsers.length > 0">
      <h3 class="pt-4">Gebruikers van deze organisatie</h3>
      <b-row>
        <b-col
          v-for="user in subUsers"
          :key="user.UserName"
          xs="12"
          sm="12"
          md="12"
          lg="6"
          xl="4"
          class="mt-4"
        >
          <b-card
            :class="{
              'card-user': true,
              'mt-4': true,
              'card-inactive': !user.IsActive
            }"
          >
            <p>
              {{ user.UserName }}
              <br />
              <small>
                {{
                  user.RoleName === 'portal keyuser'
                    ? 'Hoofdgebruiker'
                    : 'Gebruiker'
                }}
              </small>
            </p>
            <router-link
              :to="{ name: 'editSub', params: { username: user.UserName } }"
              class="btn btn-outline-primary"
              >Gegevens wijzigen</router-link
            >
          </b-card>
        </b-col>
        <b-col cols="12" class="pt-4 mt-4">
          <b-alert v-if="subUsersMaxReached" show variant="warning">
            <font-awesome-icon icon="exclamation-circle" />Het maximale aantal
            sub gebruikers is bereikt, het is niet mogelijk om een nieuwe sub
            gebruiker aan te maken.
          </b-alert>

          <b-row>
            <b-col cols="12" class="mt-4">
              <router-link
                :class="{
                  btn: true,
                  'btn-primary': true,

                  'd-none': subUsersMaxReached
                }"
                :title="
                  subUsersMaxReached
                    ? 'Maximale aantal contactpersonen berijkt'
                    : 'Nieuw contactpersoon'
                "
                :to="{ name: 'newSub' }"
                >Nieuwe gebruiker toevoegen</router-link
              >
            </b-col>

            <b-col cols="12" class="mt-4">
              <hr />
              <router-link
                :to="{ name: 'editSub', params: { username: user.UserName } }"
                class="btn btn-primary"
                >Mijn gegevens wijzigen</router-link
              >
            </b-col>
            <b-col class="mt-4">
              <router-link class="btn btn-primary" to="/registreren"
                >Nieuwe vereniging registreren</router-link
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import { getReservations } from '@/services/ReservationService'
import { getSubs } from '@/services/AuthorizationService'
import Breadcrumbs from '@/components/header/BreadCrumbs'
import dayjs from 'dayjs'

export default {
  components: { Breadcrumbs },
  data() {
    return {
      reservations: [],
      loadingReservations: true,
      reservationsIndex: 1,
      reservationsPerPageCount: 10,
      reservationsTotal: 0,
      fields: [
        {
          key: 'ReservationID',
          label: 'Aanvraag'
        },
        {
          key: 'Status',
          label: 'Status'
        },
        {
          key: 'TotalIncVAT',
          label: 'Totaal',
          class: 'text-right'
        },
        {
          key: 'WarehouseID',
          label: 'Locatie'
        },
        {
          key: 'DateTimeBusinessStart',
          label: 'Afhaaldatum'
        },
        {
          key: 'DateTimeBusinessEnd',
          label: 'Terugbrengdatum'
        },
        {
          key: 'MissingTestimonies',
          label: 'Vereiste acties',
          default: 'Geen actie vereist...'
        },
        {
          key: 'DefaultDiscount',
          label: ''
        }
      ],
      subUsers: [],
      subUsersMaxReached: true
    }
  },
  computed: {
    user: function() {
      return this.$store.state.user
    },
    account: function() {
      return this.$store.getters.account
    },
    alertInfoMessage: function() {
      if (this.$route.query.infoMessage) return this.$route.query.infoMessage
      return null
    },
    reservationsIndexCount: function() {
      return Math.ceil(this.reservationsTotal / this.reservationsPerPageCount)
    }
  },
  metaInfo: function() {
    return {
      title: `Je account`,
      titleTemplate: '%s - Vlaams-Brabant Uitleendienst'
    }
  },
  watch: {
    reservationsIndex: async function() {
      await this.getReservations()
    }
  },
  created: async function() {
    await this.getReservations()

    this.subUsers = await getSubs()
    this.subUsersMaxReached =
      this.subUsers[0].CUST_MaxUserCount <= this.subUsers.length
  },
  methods: {
    getReservations: async function() {
      this.loadingReservations = true
      const result = await getReservations({
        count: this.reservationsPerPageCount,
        index: this.reservationsIndex
      })

      this.reservations = result.Collection

      this.reservationsTotal = result.Total

      this.loadingReservations = false
    },
    reservationStatus: function(status, WarehouseID) {
      if (WarehouseID.includes('MINI')) return 'Schade'
      if (status === 'Active' || status === 'Open' || status === 'Actief')
        return 'Aangevraagd'

      if (status === 'Order') return 'Gereserveerd'

      if (status === 'Pick' || status === 'Picked') return 'In behandeling'

      if (status === 'Pending') return 'Uitgeleend'

      if (status === 'Closed') return 'Ingeleverd'

      if (status === 'Cancelled' || status === 'Expired') return 'Geannuleerd'

      if (status === 'Rejected') return 'Afgewezen'

      return status
    },
    formatDate(date) {
      return dayjs(date).format('DD-MM-YYYY')
    }
  }
}
</script>

<style scoped lang="scss">
.card-inactive {
  p {
    color: #070808;
    opacity: 0.4;
  }
}
.card-user {
  p {
    font-size: 20px;
    line-height: 24px;
    font-family: 'DecimaPro-bold';
    text-transform: uppercase;
  }
  .btn {
    font-size: 20px;
  }
  height: 100%;
}
a {
  text-decoration: none;
}
.table-responsive {
  min-height: 451px;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  .btn-primary {
    padding-left: 10px !important;
    padding-right: 10px !important;
    line-height: 4px;
    height: 40px !important;
    min-width: 40px !important;
    font-family: 'DecimaPro';
    font-size: 18px;
    background-color: black;
  }
  .disabled.btn-primary {
    padding-left: 10px !important;
    padding-right: 10px !important;
    line-height: 4px;
    height: 40px !important;
    min-width: 40px !important;
    font-family: 'DecimaPro';
    font-size: 18px;
    background-color: black !important;
  }
  .btn-secondary {
    padding-left: 10px !important;
    padding-right: 10px !important;
    line-height: 4px;
    height: 40px !important;
    min-width: 40px !important;
    background-color: white;
    border: none;
    color: black;
    font-size: 18px;
    font-family: 'DecimaPro';
  }
  .btn-secondary:hover {
    background-color: #08436f;
    color: white;
  }
  button.btn-link {
    color: black;
  }
}
.reservation-count {
  color: #070808;
  font-size: 22;
  font-family: 'DecimaPro-bold';
}
</style>
